import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import Card from "./Card";
// import MiniGallery from "./MiniGallery";
import Tag from "./Tag";
import Search from "./Search";

import { SearchIcon } from "./Icons";

const Title = ({ children }) => (
  <div className="font-family-louize text-black-dark inline-block py-1 px-3 my-4 font-medium sm:text-5xl text-xl font-black my-4">
    {children}
  </div>
);

function Sidebar({ tags }) {
  const { allWpTag, allWpPost } = useStaticQuery(graphql`
    query SidebarQuery {
      allWpTag {
        nodes {
          name
          link
          slug
        }
      }
      allWpPost(sort: { order: DESC, fields: date }, limit: 3) {
        edges {
          node {
            date(formatString: "YYYY/MM/DD")
            title
            slug
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const finalTags = tags ? tags : allWpTag.nodes;

  return (
    <div className="">
      {/* search */}
      {/* <div className="w-full mb-16">
        <Title>Buscar</Title>

        <div className="flex w-full justify-center">
          <input
            className="border-0 bg-gray-100 rounded mr-4 border border-gray-400 focus:outline-none focus:border-indigo-500 text-base px-4 lg:w-full  w-2/4 md:w-full"
            placeholder="Digite aqui"
            type="text"
          />
          <button className="inline-flex text-black  border-0 py-2 px-6 focus:outline-none  bg-gray-300 hover:bg-gray-400 rounded text-lg">
            <SearchIcon />
          </button>
        </div>
      </div> */}
      {/* 
      <div className="my-4">
        <Search />
      </div> */}

      {/* latest/popupar */}
      {/* <Title>Recentes</Title>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3">
        {allWpPost.edges &&
          allWpPost.edges.map(({ node }) => (
            <Card key={node.slug} {...node} className="w-full" fluid />
          ))}
      </div> */}

      {/* tag cloud */}
      <Title>Tags</Title>
      {finalTags && (
        <div className="py-4">
          {finalTags.nodes.map(tag => (
            <Tag key={tag.link} {...tag} />
          ))}
        </div>
      )}
      {/* gallery */}
      {/* <Title>Galeria</Title> */}
      {/* <MiniGallery /> */}

      {/* ad */}
      {/* social */}
    </div>
  );
}

export default Sidebar;
