import React from "react";

import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import { get, has } from "lodash";
// import contentParser from "gatsby-wpgraphql-inline-images";
import parse from "html-react-parser";
import moment from "moment";
import "moment/locale/pt";

import Layout from "../components/layout";
import Sidebar from "../components/Sidebar";
import SEO from "../components/MetaSeo";
import Share from "../components/Share";
import Card from "../components/Card";
import { CalendarIcon } from "../components/Icons";
import Youtube from "../components/Youtube";
import WhatsappButton from "../components/WhatsappButton";

import { pathToLink } from "../helper";

const FeaturedMedia = ({ media, alt }) => (
  <div className="p-4 md:w-1/2 relative mx-auto">
    <div className="h-full border-2 border-black overflow-hidden">
      {/* <img className="lg:h-48 md:h-36 w-full object-cover object-center" src="https://dummyimage.com/720x400" alt="blog" /> */}
      {has(media, "localFile") && (
        <Img
          className="w-full object-cover object-center"
          alt={alt}
          fluid={get(media, "localFile.childImageSharp.fluid")}
        />
      )}

      {/* <div className="p-6">
        <h2 className="tracking-widest text-xs title-font font-medium text-gray-500 mb-1">CATEGORY</h2>
        <h1 className="title-font text-lg font-medium text-gray-900 mb-3">The Catalyzer</h1>
        <p className="leading-relaxed mb-3">Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.</p>
      </div> */}
    </div>
  </div>
);

const Hero = ({ title, featured_media, slug, categories, author, date }) => (
  <div className="block md:flex md:space-x-2 bg-blue-100 sm:p-10">
    <div className="text-center w-full relative inline-block">
      {/* <div
        className="absolute left-0 bottom-0 w-full h-full z-10"
        style={{
          backgroundImage: "linear-gradient(180deg,transparent,rgba(0,0,0,.7))",
        }}
      ></div> */}

      {/* {featured_media.localFile && (
        <Img
          className="absolute left-0 top-0 w-full h-full z-0 object-cover"
          alt={slug}
          fluid={featured_media.localFile.childImageSharp.fluid}
        />
      )} */}

      <div className="container z-20 w-full text-center">
        {get(categories, "nodes", []).map(category => (
          <Link
            to={pathToLink(category.link)}
            key={category.slug}
            className="uppercase rounded inline-block py-1 px-3 m-4 bg-red-500 text-white text-sm font-medium tracking-widest"
          >
            {category.name}
          </Link>
        ))}

        <h1
          className="text-4xl md:text-6xl text-blue-400 text-center font-family-louize tracking-tighter"
          dangerouslySetInnerHTML={{ __html: title }}
        />

        <div className="text-center text-black flex justify-center my-4">
          <div className="flex mt-3">
            {/* <img
                src={author.avatar.url}
                className="h-10 w-10 rounded-full mr-2 object-cover"
              /> */}
            <div>
              {/* <p className="font-semibold text-white text-sm">
                  {author.name}{" "}
                </p> */}
              <p className="font-semibold text-red-dark text-xs">
                <CalendarIcon />{" "}
                {moment(Date.parse(date))
                  .locale("pt")
                  .fromNow()}{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Title = ({ children }) => (
  <div className="font-family-louize text-cream inline-block py-1 px-3 my-4 font-medium sm:text-5xl text-1xl font-black my-4">
    {children}
  </div>
);

const RelatedPost = ({ posts }) => (
  <>
    <Title>Relacionados</Title>
    <div className="flex flex-wrap">
      {posts && posts.map(node => <Card key={node.slug} {...node} fluid />)}
    </div>
  </>
);

const Post = ({
  data: {
    wpPost: {
      slug,
      title,
      featuredImage,
      categories,
      date,
      modified,
      excerpt,
      tags,
      author
    },
    blog: {
      postBy: { content }
    },
    site: {
      siteMetadata: { siteUrl }
    },
    allWpPost
  }
}) => {
  const wordPressUrl = process.env.GATSBY_WP_URL;
  const uploadsUrl = `${process.env.GATSBY_WP_URL}wp-content/uploads/`;

  return (
    <Layout>
      <SEO
        title={title}
        description={excerpt}
        image={get(featuredImage, "node.sourceUrl", null)}
        pathname={slug}
        article={{
          dateModified: modified,
          datePublished: date,
          author: author
        }}
      />
      <article>
        <Hero
          {...{
            slug,
            title,
            categories,
            author,
            date,
            modified,
            excerpt
          }}
        />

        {featuredImage && <FeaturedMedia media={featuredImage.node} />}

        <section className="body-font post-header">
          <div className="container mx-auto flex px-5 py-0  items-center justify-center flex-col">
            <div className="text-center lg:w-2/3 w-full">
              <div className="text-center flex justify-center my-4">
                <Share
                  url={`${siteUrl}/${slug}`}
                  title={title}
                  media={get(featuredImage, "node.sourceUrl", null)}
                />
              </div>
            </div>
          </div>
        </section>

        <div className="container mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4">
            <section className="col-span-4 p-2 lg:p-20 rounded">
              {/* {featuredVideo.featuredvideo && (<Youtube title={title} description={excerpt} url={featuredVideo.featuredvideo} date={date} />)} */}
              {/* <div
                className="entry-content text-lg"
                dangerouslySetInnerHTML={{ __html: content }}
              /> */}

              <div className="entry-content text-lg">{parse(content)}</div>

              {/* <div className="entry-content text-lg">
                {contentParser({ content }, { wordPressUrl, uploadsUrl })}
              </div> */}
            </section>
          </div>
        </div>
      </article>

      {/* <div className="container mx-auto">
        <Sidebar tags={tags} />
      </div> */}

      <div className="bg-blue-400">
        <div className="container mx-auto">
          <RelatedPost posts={allWpPost.nodes} />
        </div>
      </div>

      <WhatsappButton />
    </Layout>
  );
};

export default Post;

export const postQuery = graphql`
  query post($slug: String!, $categories: [String]) {
    wpPost(slug: { eq: $slug }) {
      date
      modified
      title
      slug
      excerpt
      content
      author {
        node {
          name
          slug
          id
        }
      }
      tags {
        nodes {
          name
          slug
          link
        }
      }
      categories {
        nodes {
          name
          slug
          link
        }
      }
      featuredImage {
        node {
          sourceUrl
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1800) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }

    blog {
      postBy(slug: $slug) {
        content
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }

    allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { slug: { in: $categories } } } }
      }
      limit: 3
    ) {
      nodes {
        title
        slug
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
